<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Aliyos</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <!-- <div class="row">
                        <div class="col-md-12">
                          <base-input
                            alternative=""
                            placeholder="Auction Code"
                            label="Auction Code"
                            input-classes="form-control-alternative"
                            :value="model.auction_code"
                            @input="
                              (event) =>
                                (model.auction_code = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.auction_code.$error"
                          >
                            Auction Code is required.
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Parshas"
                            placeholder="Parshas"
                            input-classes="form-control-alternative"
                            :value="model.auction_name"
                            @input="
                              (event) =>
                                (model.auction_name = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.auction_name.$error"
                          >
                            Parshas is required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            input-classes="form-control-alternative"
                            label="Date"
                            placeholder="Date"
                          >
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              :config="{ altFormat: 'd/m/Y', altInput: true }"
                              class="form-control datepicker"
                              v-model="model.auction_date"
                            >
                            </flat-picker>
                          </base-input>
                          <div
                            class="error mb-2"
                            v-if="v$.model.auction_date.$error"
                          >
                            Date is required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input label="Select Room">
                            <el-select
                              style="width: 100%"
                              v-model="model.room"
                              placeholder=""
                              :value="model.room"
                              filterable
                            >
                              <el-option
                                v-for="option in manageroomData"
                                :value="option.id"
                                :label="option.name"
                                :key="option.name"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.auction_template.$error"
                          >
                            Auction Template is required.
                          </div> -->
                        </div>
                        <div class="col-lg-6">
                          <base-input label="Auction Template">
                            <el-select
                              style="width: 100%"
                              v-model="model.auction_template"
                              placeholder=""
                              :value="model.auction_template"
                              filterable
                              @change="onTemplateChange($event)"
                            >
                              <el-option
                                v-for="option in auctionTemplateData"
                                :value="option.id"
                                :label="option.auction_template_name"
                                :key="option.auction_template_name"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.auction_template.$error"
                          >
                            Auction Template is required.
                          </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            input-classes="form-control-alternative"
                            label="Comment"
                            :value="model.auction_comment"
                          >
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Comment"
                              v-model="model.auction_comment"
                            ></textarea>
                          </base-input>
                        </div>
                      </div>
                    </div>
                    <div class="row1">
                      <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark' : ''"
                          :thead-classes="
                            type === 'dark' ? 'thead-dark' : 'thead-light'
                          "
                          :key="index"
                          tbody-classes="list"
                          :data="rowData"
                        >
                          <template v-slot:columns>
                            <th>Item</th>
                            <th>Member</th>
                            <th>Charge($)</th>
                            <th>Comment</th>
                            <th>
                              <i
                                class="ni ni-fat-add text-green"
                                style="font-size: large"
                                v-on:click="addRow()"
                              >
                              </i>
                            </th>
                          </template>

                          <template v-slot:default="row">
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                              >
                                <el-select
                                  input-classes="form-control-alternative"
                                  placeholder=""
                                  filterable
                                  v-model="row.item.item_id"
                                  :value="row.item.item_id"
                                >
                                  <el-option
                                    v-for="option in itemData"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.item_name"
                                    :key="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </td>
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                              >
                                <el-select
                                  input-classes="form-control-alternative"
                                  placeholder=""
                                  filterable
                                  v-model="row.item.member_id"
                                  :value="row.item.member_id"
                                >
                                  <el-option
                                    v-for="option in memberData"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.full_name"
                                    :key="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </td>
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                                @input="
                                  (event) =>
                                    (row.item.charges = event.target.value)
                                "
                                :value="row.item.charges"
                              />
                            </td>
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                                @input="
                                  (event) =>
                                    (row.item.comments = event.target.value)
                                "
                                :value="row.item.comments"
                              />
                            </td>
                            <td style="display: none">
                              {{ (row.item.charge_type = "Auction") }}
                            </td>
                            <td>
                              <i
                                class="ni ni-fat-delete text-red"
                                style="font-size: large"
                                v-on:click="deleteRow(index)"
                              >
                              </i>
                            </td>
                          </template>
                        </base-table>
                      </div>
                    </div>
                    <div></div>
                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ElSelect, ElOption } from "element-plus";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const auctionRepository = RepositoryFactory.get("auction");
const memberRepository = RepositoryFactory.get("member");
const itemRepository = RepositoryFactory.get("item");
const addchargeRepository = RepositoryFactory.get("addcharge");
const auctiontemplateRepository = RepositoryFactory.get("auction_template");
const manageroomRepository = RepositoryFactory.get("manageroom");
export default {
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "Auction",
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      hide: false,
      isUpdateLoading: false,
      rowData: [{}],
      memberData: [{}],
      itemData: [{}],
      model: {},
      auctionTemplateData: [],
      manageroomData: [],
    };
  },
  validations() {
    return {
      model: {
        auction_name: { required },
        // auction_code: { required },
        auction_date: { required },
        // auction_template: { required },
      },
    };
  },

  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      this.model.auctiondata = JSON.stringify(this.rowData);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await auctionRepository.create(data);
      let data2 = RepositoryFactory.JSON_to_URLEncoded(
        {
          row: this.rowData,
          model: this.model,
          charge_id: response.data.id,
        },
        "data",
        []
      );
      let response2 = await addchargeRepository.create_auction_charge(data2);
      if (response2.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/auction/view_auction");
        }, 1000);
      }
    },
    async Update() {
      this.model.auctiondata = this.rowData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await auctionRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/auction/view_auction");
        }, 1000);
      }
    },

    addRow() {
      this.rowData.push({});
    },
    deleteRow(i) {
      this.rowData.splice(i, 1);
    },
    getRoomList() {
      manageroomRepository.getManageroom().then((data) => {
        this.tableData = Object.freeze(data);
        this.manageroomData = this.tableData.data;
      });
    },
    getList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.memberData = this.tableData.data;
      });
    },
    getAuctionList() {
      itemRepository.getAuction().then((data) => {
        this.tableData = Object.freeze(data);
        this.itemData = this.tableData.data;
      });
    },
    getAuctionTemplateList() {
      auctiontemplateRepository.getAuctionTemplate().then((data) => {
        this.tableData = Object.freeze(data);
        this.auctionTemplateData = this.tableData.data;
      });
    },
    onTemplateChange(value) {
      auctiontemplateRepository.getAuctionTemplateByid(value).then((data) => {
        this.tableData = Object.freeze(data);
        this.rowData = this.tableData.data[0].auctiontempadd;
      });
    },
  },
  mounted() {
    this.id = this.$route.params;
    this.model = {};
    if (this.id.data != "undefined") {
      auctionRepository.getAuctionByid(this.id.data).then((data) => {
        if (data.data.length != 0) {
          this.model = data.data[0];
          // this.rowData = data.data[0].auctiondata;
          this.rowData = Object.assign([], data.data[0].auctiondata);
        }
      });
    }
    this.getRoomList();
    this.getList();
    this.getAuctionList();
    this.getAuctionTemplateList();
  },
};
</script>

<style></style>
